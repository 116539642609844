import "./ErrorPage.scss";

interface ErrorPageProps {
  code?: number,
  message?: string
};

export default function ErrorPage({
  code = 404,
  message = `The page you're looking for doesn't exist.`
} : ErrorPageProps) {
  
  return (
    <div data-component="ErrorPage">
      <div className="content">
        <div className="error-code">
          {code}.
        </div>
        <div className="error-description">
          Oops. That's an error.<br /><br />
          {message}
        </div>
      </div>
    </div>
  );

}