import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import { AgentSchema, DiscordLinkSchema, PackageSchema } from "../utils/types";

import { AlertModal } from "../components/modals/AlertModal";

import {
  Plus
} from 'react-feather';
import { useAuth } from "../context/AuthContext";
import DiscordLinkCard from "../components/cards/DiscordLinkCard";
import AgentCard from "../components/cards/AgentCard";
import Button from "../components/button/Button";
import API, { ErrorWithDetails } from "../utils/api";

import "./DashboardPage.scss";

function DashboardPage() {

  const navigate = useNavigate();
  const { user, organization } = useAuth();

  const [discordLinks, setDiscordLinks] = useState<Array<DiscordLinkSchema>>([]);
  const [agents, setAgents] = useState<Array<AgentSchema>>([]);
  const [packages, setPackages] = useState<Array<PackageSchema>>([]);
  const [showCreateAgentModal, setShowCreateAgentModal] = useState(false);

  const handleLogout = async (e: any) => {
    e.preventDefault();
    await API.logout();
    navigate('/');
  };

  useEffect(() => {
    (async () => {
      const discordLinksResponse = await API.get('v1/discord_links', {organization: organization.name});
      if (discordLinksResponse) {
        const discordLinks = discordLinksResponse.data as Array<DiscordLinkSchema>;
        setDiscordLinks(discordLinks);
      }
    })();
    (async () => {
      const agentsResponse = await API.get(
        'v1/agents',
        {organization: organization.name, includeDiscord: true}
      );
      if (agentsResponse) {
        const agents = agentsResponse.data as Array<AgentSchema>;
        setAgents(agents);
      }
    })();
    (async () => {
      const packagesResponse = await API.get(
        'v1/packages',
        {organization: organization.name}
      );
      if (packagesResponse) {
        const packages = packagesResponse.data as Array<PackageSchema>;
        setPackages(packages);
      }
    })();
  }, []);

  const createAgent = async (name: string) => {
    const agentResult = await API.post(
      "v1/agents",
      {
        organization: organization.name,
        name: name,
        prompt: `<auto>`
      }
    );
    const agent = agentResult as AgentSchema;
    navigate(`/agents?agent_id=${agent.unique_id}`);
  };

  return (
    <>
      {showCreateAgentModal && (
        <AlertModal
          message="Enter your new agent name"
          type="text"
          color="green"
          onClose={() => setShowCreateAgentModal(false)}
          onConfirm={async (complete, value) => {
            await createAgent(value);
            complete();
          }}
          onCancel={() => {}}
        />
      )}
      <div data-component="DashboardPage">
        <div className="content">
          <div className="item-title">
            My Discord bots
            <Button
              label="Create new bot"
              icon={Plus}
              size="small"
              href="/setup"
              />
          </div>
          <div className="item-description">
            You can change the agent associated with your Discord server
            at any time.
          </div>
          <div className="item-group">
            {discordLinks.map((discordLink, i) => {
              return <div className="item-entry" key={discordLink.unique_id}>
                <DiscordLinkCard
                  discordLink={discordLink}
                  onUnlink={id => {
                    setDiscordLinks(discordLinks.filter(discordLink => discordLink.unique_id !== id));
                  }}
                  />
              </div>
            })}
          </div>
          <div className="item-title">
            My Agents
            <Button
              label="Create new agent"
              icon={Plus}
              size="small"
              onClick={() => setShowCreateAgentModal(true)}
              />
          </div>
          <div className="item-description">
            Agents can be assigned to multiple servers or none at all.
          </div>
          <div className="item-group">
            {agents.map((agent, i) => {
              return <div className="item-entry" key={agent.unique_id}>
                <AgentCard
                  key={agent.unique_id}
                  agent={agent}
                  onArchive={(id) => {
                    setAgents(agents.filter(agent => agent.unique_id !== id));
                  }}
                />
              </div>;
            })}
          </div>
          <div className="item-title">
            My Functs
            <span>( <Link to="/packages">Browse all packages</Link> )</span>
          </div>
          <div className="item-description">
            Create custom actions for your bot by
            reading our developer docs
            at <Link to="https://github.com/functme/cli">github.com/functme/cli</Link>.
          </div>
          <div className="item-group">
            {packages.map((pkg, i) => {
              return (
                <div className="item-entry" key={i}>
                  <Link to={`/packages/${pkg.display_name}`}>
                    {pkg.display_name}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );

}

export default DashboardPage;