import { ReactElement, Suspense } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  LoaderFunction
} from 'react-router-dom';
import { useAuth } from './context/AuthContext';

import Nav from './components/nav/Nav';
import Footer from './components/nav/Footer';
import IndexPage from './pages/IndexPage';
import DashboardPage from './pages/DashboardPage';
import SignupPage from './pages/signup/SignupPage';
import LoginPage from './pages/signup/LoginPage';
import Login3pPage from './pages/signup/Login3pPage';
import SetupPage from './pages/setup/SetupPage';
import AgentsPage from './pages/agents/AgentsPage';
import BillingPage from './pages/billing/BillingPage';
import PackagesPage from './pages/packages/PackagesPage';
import PackageDetailsPage from './pages/packages/PackageDetailsPage';
import PackageDetailsPageLoader from './pages/packages/PackageDetailsPageLoader';
import ErrorPage from './pages/error/ErrorPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';

interface Page {
  path: string,
  element: ReactElement,
  loader?: LoaderFunction,
  requireLogin?: boolean,
  hideNav?: boolean
};

function Router() {

  const { user } = useAuth();

  const Pages: Page[] = [
    {
      path: "/",
      element: user ? <Navigate to="/dashboard" /> : <IndexPage />,
    },
    {
      path: "/login",
      element: <LoginPage />,
      hideNav: true
    },
    {
      path: "/signup",
      element: <SignupPage />,
      hideNav: true
    },
    {
      path: "/login-3p",
      element: <Login3pPage mode="login" />,
      hideNav: true
    },
    {
      path: "/link-3p",
      element: <Login3pPage mode="link" />,
      requireLogin: true
    },
    {
      path: "/dashboard",
      element: <DashboardPage />,
      requireLogin: true,
    },
    {
      path: "/setup",
      element: <SetupPage />,
      requireLogin: true,
      hideNav: true,
    },
    {
      path: "/agents",
      element: <AgentsPage />,
      requireLogin: true
    },
    {
      path: "/billing",
      element: <BillingPage />,
      requireLogin: true
    },
    {
      path: "/packages",
      element: <PackagesPage />,
      requireLogin: false
    },
    {
      path: "/packages/private/:org/:name/:environment?/*",
      element: <PackageDetailsPage />,
      loader: PackageDetailsPageLoader({isPrivate: true}),
      requireLogin: false
    },
    {
      path: "/packages/:org/:name/:environment?/*",
      element: <PackageDetailsPage />,
      loader: PackageDetailsPageLoader({isPrivate: false}),
      requireLogin: false
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicyPage />,
      requireLogin: false
    },
    {
      path: "/terms-of-service",
      element: <TermsOfServicePage />,
      requireLogin: false
    },
    {
      path: "/*",
      element: <ErrorPage />,
      requireLogin: false
    }
  ];

  const router = createBrowserRouter(Pages.map(page => {
    const loadingElement = (
      <div>Loading ...</div>
    );
    const elementWithNav = page.hideNav
      ? (
          <div className="page-content">
            <Suspense fallback={loadingElement}>
              {page.element}
            </Suspense>
          </div>
        )
      : (
          <>
            <Nav />
            <div className="page-content">
              <Suspense fallback={loadingElement}>
                {page.element}
              </Suspense>
              <Footer />
            </div>
          </>
        );
    const element = (!page.requireLogin || user) ? elementWithNav : <Navigate to="/login" />;
    return {
      path: page.path,
      element: element,
      loader: page.loader,
      errorElement: <><Nav /><div className="page-content"><ErrorPage /></div></>
    };
  }));

  return <RouterProvider router={router} />;

}

export default Router;
