import { Link } from 'react-router-dom';
import {
  ChevronRight,
  Icon,
  Loader
} from 'react-feather';
import './Button.scss';
import { MouseEventHandler } from 'react';

function Button({
  label = "Submit",
  size = "medium",
  color = "default",
  href = void 0,
  target = "_self",
  icon = ChevronRight,
  disabled = false,
  loading = false,
  postfix = false,
  onClick = () => {}
} : {
  label?: string,
  size?: "small" | "medium",
  color?: "default" | "blurple" | "green" | "red" | "orange" | "blue"
  href?: string,
  target?: "_self" | "_blank" | "_parent" | "_top" | string,
  icon?: Icon,
  disabled?: boolean,
  loading?: boolean,
  postfix?: boolean,
  onClick?: MouseEventHandler
}) {
  const StartIcon = icon;
  return (
    <div data-component="Button" className={postfix ? "postfix" : ""} data-size={size} data-color={color}>
      <button onClick={onClick} disabled={loading || disabled}>
        {!!href &&
          <Link to={href} target={target}>
            <span className="prefix">
              {loading ? <Loader className="loading-spin" /> : <StartIcon />}
            </span>
            <span className="label">{label}</span>
          </Link>
        }
        {!href && 
          <a>
            <span className="prefix">
              {loading ? <Loader className="loading-spin" /> : <StartIcon />}
            </span>
            <span className="label">{label}</span>
          </a>
        }
      </button>
    </div>
  );
}

export default Button;
