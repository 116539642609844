import { Link } from "react-router-dom";
import "./LegalPage.scss";

export default function TermsOfServicePage() {
  
  return (
    <div data-component="TermsOfServicePage" className="legal-body">
      <h1>Terms of Service</h1>
      <p><strong>Last Updated:</strong> 2024-08-18</p>
      
      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing and using <strong>Funct, Inc.</strong> (“we,” “our,” or “us”) at <Link to="https://funct.me">funct.me</Link> (the “Site”), you agree to be bound by these Terms of Service (“Terms”). 
        If you do not agree to these Terms, please do not use the Site.
      </p>
      
      <h2>2. Eligibility</h2>
      <p>You must be at least 13 years of age to create an account and use the Site. By using the Site, you represent and warrant that you meet this age requirement.</p>
      
      <h2>3. User Accounts</h2>
      <p><strong>Registration:</strong> To access certain features of the Site, you must create an account by providing accurate and complete information.</p>
      <p><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account information and for any activities that occur under your account.</p>
      
      <h2>4. User-Generated Content</h2>
      <p><strong>License Grant:</strong> By creating, publishing, or uploading any content on the Site, including but not limited to custom AI chatbots and code (“User Content”), you grant us a perpetual, worldwide, non-exclusive, royalty-free license to use, modify, reproduce, distribute, and resell your User Content in any form or medium, including in a marketplace or other platforms we operate.</p>
      <p><strong>Revenue Sharing:</strong> At our discretion, you may be eligible to participate in revenue-sharing opportunities when your User Content is sold or otherwise monetized through our marketplace. Details of such opportunities will be provided to you if and when you are eligible.</p>
      <p><strong>Responsibility:</strong> You are solely responsible for the User Content you create and publish on the Site. You must ensure that your content does not infringe on any third-party rights or violate any applicable laws.</p>
      
      <h2>5. Prohibited Conduct</h2>
      <p>You agree not to engage in any of the following prohibited activities:</p>
      <ul>
        <li>Violating any applicable laws or regulations.</li>
        <li>Uploading, publishing, or transmitting any content that is unlawful, defamatory, obscene, or harmful.</li>
        <li>Using the Site to engage in fraudulent activities or impersonate another person.</li>
      </ul>
      
      <h2>6. Intellectual Property</h2>
      <p>All content on the Site, excluding User Content, is the property of <strong>Funct, Inc.</strong> or our licensors and is protected by intellectual property laws. You may not use any of our content without our prior written permission.</p>
      
      <h2>7. Termination</h2>
      <p>We reserve the right to suspend or terminate your account at any time, with or without notice, for any reason, including violation of these Terms.</p>
      
      <h2>8. Disclaimers and Limitation of Liability</h2>
      <p><strong>Disclaimers:</strong> The Site is provided on an “as-is” and “as available” basis. We make no warranties or representations about the accuracy or completeness of the Site’s content and disclaim any liability for any errors or omissions.</p>
      <p><strong>Limitation of Liability:</strong> To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, or consequential damages arising out of your use of the Site, even if we have been advised of the possibility of such damages.</p>
      
      <h2>9. Governing Law</h2>
      <p>These Terms are governed by and construed in accordance with the laws of the State of California, without regard to its conflict of law principles.</p>
      
      <h2>10. Changes to These Terms</h2>
      <p>We may update these Terms from time to time. Any changes will be posted on this page, and the date of the latest revision will be indicated at the top. Your continued use of the Site after any such changes constitutes your acceptance of the new Terms.</p>
      
      <h2>11. Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us at <Link to="mailto:support@funct.me">support@funct.me</Link>.</p>
      
      <p><strong>Funct, Inc.</strong></p>
      <p><Link to="mailto:support@funct.me">support@funct.me</Link></p>
    </div>
  );

}