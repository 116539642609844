import { AuthProvider } from './context/AuthContext';
import Router from './Router';

import './App.scss';

function App() {

  return (
    <div data-component="App">
      <AuthProvider>
        <Router />
      </AuthProvider>
    </div>
  );
}

export default App;
