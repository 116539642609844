import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Button from '../../components/button/Button';
import API, { ErrorWithDetails } from '../../utils/api';

import { ReactComponent as DiscordLogo } from "../../svg/discord-mark-white.svg";

import './SignupPage.scss';

function Login3pPage({
  mode
} : {
  mode: "login" | "link"
}) {

  const navigate = useNavigate();
  const location = useLocation();

  const hasMounted = useRef(false);

  const [error, setError] = useState<ErrorWithDetails | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleDiscordLogin = async () => {
    if (isLoading) {
      return;
    }
    setError(null);
    setIsLoading(true);
    try {
      const result = await API.post('v1/discord_user_auths', {mode});
      window.location = result?.url;
    } catch (e) {
      setError(e as ErrorWithDetails);
      setIsLoading(false);
    }
  };

  useEffect(() => {

    // Force 1x run in strict mode
    if (!hasMounted.current) {
      hasMounted.current = true;
      const query = new URLSearchParams(location.search);

      if (query.has("error")) {
        const error = query.get("error") || "";
        query.delete("error");
        const defaultError = new Error(error.trim()) as ErrorWithDetails;
        setError(defaultError);
      }

      if (query.has("discord_user_auth_token")) {
        const token = query.get("discord_user_auth_token") || "";
        query.delete("discord_user_auth_token");
        if (mode === "login") {
          const login = async () => {
            setIsLoading(true);
            try {
              const loginResult = await API.login3p({source: "discord", token});
              if (loginResult?.is_new_registration) {
                navigate('/setup');
              } else {
                navigate('/dashboard');
              }
            } catch (e) {
              setError(e as ErrorWithDetails);
              setIsLoading(false);
            }
          };
          login();
        } else if (mode === "link") {
          const link = async () => {
            setIsLoading(true);
            try {
              const result = await API.post('v1/discord_users', {token});
              navigate('/dashboard');
              navigate(0); // FIXME: Nav hack; this is broken
            } catch (e) {
              setError(e as ErrorWithDetails);
              setIsLoading(false);
            }
          }
          link();
        }
      }

      navigate({search: query.toString()}); 

    }

  }, []);

  return (
    <div data-component="Login3pPage">
      <div className="center-container">
        <div className="center-modal">
          <div className="form-intro">
            {mode === "login" && <>Logging in to <strong>Funct</strong> via Discord</>}
            {mode === "link" && <>Linking Discord account</>}
          </div>
          <div className="form-row login-discord login-3p">
            <Button
              color="blurple"
              size="medium"
              icon={DiscordLogo}
              label={isLoading
                  ? mode === "login"
                    ? "Logging in..."
                    : "Linking..."
                  : mode === "login"
                    ? "Login with Discord"
                    : "Link Discord account"
              }
              onClick={handleDiscordLogin}
              loading={isLoading}
              />
          </div>
          {error &&
            !error?.details?.email &&
            !error?.details?.password && (
            <div className="form-row margin-top error">
              {error.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login3pPage;
