import { useState, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import Textbox from '../../components/textbox/Textbox';
import Button from '../../components/button/Button';
import API, { ErrorWithDetails } from '../../utils/api';

import { ReactComponent as DiscordLogo } from "../../svg/discord-mark-white.svg";

import './SignupPage.scss';

function SignupPage() {

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [error, setError] = useState<ErrorWithDetails | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(query.get("email") || "");

  const emailRef = useRef(null);
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  const handleSignup = async () => {
    if (isLoading) {
      return;
    }
    setError(null);
    setIsLoading(true);
    const params = {
      email: (emailRef.current || {value: ''}).value,
      organization_name: (usernameRef.current || {value: ''}).value,
      password: (passwordRef.current || {value: ''}).value,
      repeat_password: (passwordRef.current || {value: ''}).value
    };
    const loginParams = {
      username: (emailRef.current || {value: ''}).value,
      password: (passwordRef.current || {value: ''}).value,
    };
    try {
      await API.post('users', params);
      await API.login(loginParams);
      setIsLoading(false);
      navigate('/setup');
    } catch (e) {
      setError(e as ErrorWithDetails);
      setIsLoading(false);
    }
  };

  const handleDiscordLogin = async () => {
    if (isLoading) {
      return;
    }
    setError(null);
    setIsLoading(true);
    try {
      const result = await API.post('v1/discord_user_auths', {mode: 'login'});
      window.location = result?.url;
    } catch (e) {
      setError(e as ErrorWithDetails);
      setIsLoading(false);
    }
  };

  return (
    <div data-component="SignupPage">
      <div className="center-container">
        <div className="center-modal">
          <div className="form-intro">
            Sign up for <strong>Funct</strong>
          </div>
          <div className="form-row login-discord">
            <Button
              color="blurple"
              size="medium"
              icon={DiscordLogo}
              label="Login with Discord"
              onClick={handleDiscordLogin}
              loading={isLoading}
              />
          </div>
          <div className="or-row">
            <div className="line"></div>
            <div className="or-text">OR</div>
            <div className="line"></div>
          </div>
          <div className="form-row">
            <Textbox
              error={error?.details?.email?.message}
              onChange={(value) => {
                error && error.clear && setError(error.clear('email'));
                setEmail(value);
              }}
              onSubmit={handleSignup}
              heading="email"
              value={query.get("email") || ""}
              autoFocus
              ref={emailRef}
              />
          </div>
          <div className="form-row">
            <Textbox
              error={error?.details?.name?.message}
              onChange={() => error && error.clear && setError(error.clear('name'))}
              onSubmit={handleSignup}
              heading="username"
              value={""}
              ref={usernameRef}
              />
          </div>
          <div className="form-row">
          <Textbox
            error={error?.details?.password?.message}
            onChange={() => error && error.clear && setError(error.clear('password'))}
            onSubmit={handleSignup}
            type="password"
            heading="password"
            value={""}
            ref={passwordRef}
            />
          </div>
          {error &&
            !error?.details?.email &&
            !error?.details?.name &&
            !error?.details?.password && (
            <div className="form-row margin-top error">
              {error.message}
            </div>
          )}
          <div className="form-row margin-top align-right">
            <div className="link">
              <Link
                to={`/login${email ? `?email=${encodeURIComponent(email)}` : ``}`}
              >
                Already registered?
              </Link>
            </div>
            <div className="spacer" />
            <Button
              loading={isLoading}
              label="Sign up"
              onClick={handleSignup}
              />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupPage;
