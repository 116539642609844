import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import API from '../utils/api';
import { OrganizationSchema, UserSchema, WalletSchema } from '../utils/types';

// Define the shape of the authentication context
interface AuthContextType {
  user: UserSchema | null;
  organization: OrganizationSchema,
  wallet: WalletSchema,
  setUser: (user: UserSchema | null) => void;
}

// Create the authentication context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Create a provider component
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<UserSchema | null>(API.user);
  const organization = user?.memberships[0]?.organization || ({
    name: '?',
    email: '?'
  } as OrganizationSchema);
  const wallet = organization.wallet || ({microcredit_balance: 0} as WalletSchema);
  useEffect(() => {
    API.setUserCallback((user) => setUser(user));
    API.refreshUser();
  }, []);
  return (
    <AuthContext.Provider value={{ user, organization, wallet, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

// Create a custom hook to use the authentication context
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};