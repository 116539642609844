import { Link } from "react-router-dom";
import "./LegalPage.scss";

export default function PrivacyPolicyPage() {
  
  return (
    <div data-component="PrivacyPolicyPage" className="legal-body">
      <h1>Privacy Policy</h1>
      <p><strong>Last Updated:</strong> 2024-08-18</p>
      
      <h2>1. Introduction</h2>
      <p>
        Welcome to <strong>Funct, Inc.</strong> (“we,” “our,” or “us”). We value your privacy and are committed to protecting your personal information. 
        This Privacy Policy outlines how we collect, use, and safeguard your data when you use our website, <Link to="https://funct.me">funct.me</Link> (the “Site”).
      </p>
      
      <h2>2. Information We Collect</h2>
      <p><strong>Personal Information:</strong> When you sign up for an account, we may collect personal information such as your name, email address, and any other details you provide.</p>
      <p><strong>Usage Data:</strong> We automatically collect information about your interactions with the Site, such as your IP address, browser type, and the pages you visit.</p>
      <p><strong>User-Generated Content:</strong> Any content you create or publish on the Site, including custom AI chatbots and code, may be stored and displayed publicly.</p>
      
      <h2>3. How We Use Your Information</h2>
      <p>We use your information to:</p>
      <ul>
        <li>Provide and improve the services offered on the Site.</li>
        <li>Communicate with you regarding your account and any updates or changes to our services.</li>
        <li>Analyze site usage and enhance user experience.</li>
        <li>Ensure compliance with legal obligations and enforce our terms of service.</li>
      </ul>
      
      <h2>4. Sharing Your Information</h2>
      <p>We do not sell your personal information. We may share your information with third parties only in the following circumstances:</p>
      <ul>
        <li><strong>Service Providers:</strong> We may share your data with trusted service providers who assist us in operating the Site, provided they agree to keep your information confidential.</li>
        <li><strong>Legal Requirements:</strong> We may disclose your information if required by law or to protect the rights, property, or safety of our users or the public.</li>
      </ul>
      
      <h2>5. Your Rights</h2>
      <p>As a California resident, you have the right to:</p>
      <ul>
        <li>Request access to the personal information we hold about you.</li>
        <li>Request that we delete your personal information, subject to certain exceptions.</li>
        <li>Opt-out of the sale of your personal information, though we do not sell your data.</li>
      </ul>
      <p>To exercise these rights, please contact us at <Link to="mailto:support@funct.me">support@funct.me</Link>.</p>
      
      <h2>6. Cookies and Tracking Technologies</h2>
      <p>We use cookies and similar tracking technologies to enhance your experience on our Site. You can manage your cookie preferences through your browser settings.</p>
      
      <h2>7. Data Security</h2>
      <p>We implement reasonable security measures to protect your personal information. However, no data transmission over the internet is completely secure, and we cannot guarantee the security of your information.</p>
      
      <h2>8. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the date of the latest revision will be indicated at the top.</p>
      
      <h2>9. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy, please contact us at <Link to="mailto:support@funct.me">support@funct.me</Link>.</p>
      
      <p><strong>Funct, Inc.</strong></p>
      <p><Link to="mailto:support@funct.me">support@funct.me</Link></p>
    </div>
  );

}