import { useState } from "react";
import { X } from "react-feather";
import Button from "../button/Button";
import "./AlertModal.scss";
import Textbox from "../textbox/Textbox";

export function AlertModal(
  {
    message = "Continue?",
    color = "red",
    type = "confirm",
    onClose = () => {},
    onConfirm = void 0,
    onCancel = void 0
  } : {
    message?: string
    color?: "red" | "green",
    type?: "confirm" | "text"
    onClose?: () => void,
    onConfirm?: (complete: Function, value?: any) => void,
    onCancel?: () => void
  }
) {

  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const complete = () => setIsLoading(false);

  return (
    <div data-component="AlertModal" onClick={e => !isLoading && (onCancel && onCancel(), onClose())}>
      <div className="modal" onClick={e => e.stopPropagation()}>
        <div className="message">
          {message}
        </div>
        {type === "text" && (
          <Textbox
            onChange={(value) => setValue(value)}
          />
        )}
        <div className="actions">
          {onCancel && (
            <Button
              icon={X}
              label="Cancel"
              size="small"
              disabled={isLoading}
              onClick={() => !isLoading && (onCancel(), onClose())}
            />
          )}
          {onConfirm && (
            <Button
              label="Okay"
              size="small"
              color={color}
              loading={isLoading}
              disabled={type === "text" && !value.trim()}
              onClick={() => {
                setIsLoading(true);
                onConfirm(complete, type === "text" ? value : void 0);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );

}