import { useState } from "react";
import { Link } from "react-router-dom"
import Button from "../button/Button";
import { AgentSchema } from "../../utils/types"
import API, { ErrorWithDetails } from "../../utils/api";
import { AlertModal } from "../modals/AlertModal";

import { X, Trash } from "react-feather";

import "./AgentCard.scss";

export default function AgentCard(
  {
    agent,
    onArchive = (id: string) => {}
  } :
  {
    agent: AgentSchema,
    onArchive?: (id: string) => void;
  }
) {

  const [showAlert, setShowAlert] = useState(false);

  const archiveAgent = async () => {
    const id = agent.unique_id;
    try {
      const unlinkResponse = await API.del('v1/agents', {id});
      if (unlinkResponse) {
        onArchive(id);
      }
    } catch (e) {
      const error = e as ErrorWithDetails
      alert(error.message);
    }
  };

  return (
    <>
      {showAlert && (
        <AlertModal
          message="Archive this agent?"
          onClose={() => setShowAlert(false)}
          onConfirm={async (complete) => {
            await archiveAgent();
            complete();
          }}
          onCancel={() => {}}
        />
      )}
      <div data-component="AgentCard">
        <div>
          <div>
            <Link to={`/agents?agent_id=${agent.unique_id}`}>{agent.agentConfigs?.[0]?.name}</Link>
            <br />
            in {agent.discordAgents?.length || 0} servers
          </div>
          <div className="actions">
            <Button onClick={() => setShowAlert(true)}
              icon={Trash}
              label="Archive"
              size="small"
              />
          </div>
        </div>
      </div>
    </>
  );

}