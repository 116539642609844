import {
  useState,
  forwardRef,
  useRef,
  useEffect,
  ForwardedRef,
  LegacyRef,
} from 'react';

import { Icon } from 'react-feather';
import { Loader } from 'react-feather';

import './Textbox.scss';

const useForwardRef = <T,>(
  ref: ForwardedRef<T>,
  initialValue: any = null
) => {
  const targetRef = useRef<T>(initialValue);
  useEffect(() => {
    if (!ref) return;
    if (typeof ref === 'function') {
      ref(targetRef.current);
    } else {
      ref.current = targetRef.current;
    }
  }, [ref]);
  return targetRef;
};

type TextboxProps = {
  type?: "text" | "multiline" | "chat" | "password";
  heading?: string;
  value?: string;
  placeholder?: string;
  error?: string;
  lines?: number;
  maxLines?: number;
  loading?: boolean;
  endIcon?: Icon;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
};

const Textbox = forwardRef<HTMLTextAreaElement | HTMLInputElement, TextboxProps>(({
  type = "text",
  heading,
  value,
  placeholder,
  error,
  lines = 1,
  maxLines = 1,
  loading = false,
  endIcon = null,
  autoFocus = false,
  onChange = (value: string) => {},
  onSubmit = (value: string) => {}
}, forwardedRef) => {

  if (maxLines < lines) {
    maxLines = lines;
  }

  const EndIcon = endIcon;

  const inputRef = useForwardRef<HTMLTextAreaElement | HTMLInputElement>(forwardedRef);

  const handleInput = (event: any) => {
    const input = inputRef.current;
    if (!input) {
      return;
    }
    const value = (type === 'multiline' || type === 'chat')
      ? event.target.value
      : event.target.value.replaceAll('\n', '');
    input.value = value;
    adjustHeight();
  };

  const handlePaste = (event: any) => {
    const input = inputRef.current;
    if (!input) {
      return;
    }
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    const selStart = input.selectionStart || input.value.length;
    const selEnd = input.selectionEnd || input.value.length;
    const prefix = input.value.slice(0, selStart);
    const suffix = input.value.slice(selEnd);
    input.value = prefix + paste + suffix;
    input.selectionStart = selStart + paste.length;
    input.selectionEnd = input.selectionStart;
    adjustHeight();
  };

  const handleKeydown = (event: any) => {
    const input = inputRef.current;
    if (!input) {
      return;
    }
    if (event.key === 'Enter') {
      if (
        (type === 'chat' && !event.shiftKey) ||
        (type !== 'multiline' && type !== 'chat')
      ) {
        event.preventDefault();
        if (type === "chat") {
          if (input.value) {
            onSubmit(input.value);
            input.value = '';
          }
        } else {
          onSubmit(input.value);
        }
      }
    }
  };

  const adjustHeight = () => {
    if ((type === 'multiline' || type === 'chat') && inputRef.current) {
      const el = inputRef.current as HTMLTextAreaElement;
      const computedStyle = window.getComputedStyle(el);
      const lineHeight = parseFloat(computedStyle.lineHeight);
      const paddingTop = parseFloat(computedStyle.paddingTop);
      const paddingBottom = parseFloat(computedStyle.paddingBottom);
      const borderTop = parseFloat(computedStyle.borderTop);
      const borderBottom = parseFloat(computedStyle.borderBottom);
      const minHeight = lines * lineHeight + paddingTop + paddingBottom;
      const maxHeight = maxLines * lineHeight + paddingTop + paddingBottom;
      el.style.height = 'auto';
      el.style.height = `${Math.max(minHeight, Math.min(maxHeight, el.scrollHeight)) + borderTop + borderBottom}px`;
      window.requestAnimationFrame(() => {
        const remainder = el.scrollHeight - el.scrollTop - el.offsetHeight;
        if (remainder < lineHeight) {
          el.scrollTop += remainder;
        }
      });
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [value, lines, maxLines]);

  return (
    <div data-component="Textbox" className={error ? 'error' : ''}>
      {heading && <div className="heading">{heading}</div>}
      <div className="textbox-container">
        <div className="end-icon">
          {loading && <Loader className="loading-spin" />}
          {!loading && EndIcon && <EndIcon />}
        </div>
        {(
          (type === "multiline" || type === "chat")
            ? <textarea
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                onInput={handleInput}
                onPaste={handlePaste}
                onChange={e => onChange(e.target.value || '')}
                onKeyDown={handleKeydown}
                defaultValue={value}
                placeholder={placeholder}
                rows={1}
                autoFocus={autoFocus}
                ref={inputRef as LegacyRef<HTMLTextAreaElement>}
              />
            : <input
                type={type}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                onInput={handleInput}
                onPaste={handlePaste}
                onChange={e => onChange(e.target.value || '')}
                onKeyDown={handleKeydown}
                defaultValue={value}
                placeholder={placeholder}
                autoFocus={autoFocus}
                ref={inputRef as LegacyRef<HTMLInputElement>}
                />
        )}
      </div>
      {error && <div className="error-text">{error}</div>}
    </div>
  );

});

export default Textbox;
