import { Link } from "react-router-dom";
import { PackageSchema } from "../../utils/types";
import { formatRecent } from "../../utils/time";

import "./PackageCard.scss"

interface PackageCardProps {
  package: PackageSchema
}

export default function PackageCard({
  pkg
} : {
  pkg: PackageSchema
}) {
  return (
    <div data-component="PackageCard">
      <div className="details">
        <div className="name">
          <Link to={`/packages/${pkg.display_name}`}>
            {pkg.display_name}
          </Link>
        </div>
        <div className="spacer" />
        <div className="time">
          {formatRecent(pkg.created_at)}
        </div>
      </div>
      <div className="body">
        {pkg.description}
      </div>
    </div>
  );
}