import {
  useState,
  forwardRef,
  useRef,
  useEffect,
  ForwardedRef,
  LegacyRef
} from 'react';
import './Select.scss';

const useForwardRef = <T,>(
  ref: ForwardedRef<T>,
  initialValue: any = null
) => {
  const targetRef = useRef<T>(initialValue);
  useEffect(() => {
    if (!ref) return;
    if (typeof ref === 'function') {
      ref(targetRef.current);
    } else {
      ref.current = targetRef.current;
    }
  }, [ref]);
  return targetRef;
};

interface SelectOption {
  label: string,
  value: any,
  disabled?: boolean
};

type SelectProps = {
  heading?: string;
  error?: string;
  options?: SelectOption[];
  value?: any,
  onChange?: (value: string, option: SelectOption | null) => void;
  onSubmit?: () => void;
};

export interface ExtendedHTMLSelectElement extends HTMLSelectElement {
  option?: SelectOption | null
};

const Select = forwardRef<ExtendedHTMLSelectElement, SelectProps>(({
  heading,
  error,
  options = [],
  value,
  onChange = () => {}
}, forwardedRef) => {

  const selectRef = useForwardRef<ExtendedHTMLSelectElement>(forwardedRef);

  useEffect(() => {
    selectRef.current.option = options[selectRef.current.selectedIndex] || null;
  });

  return (
    <div data-component="Select" className={error ? 'error' : ''}>
      {heading && <div className="heading">{heading}</div>}
      <div className="select-wrapper">
        <select
          value={value}
          onChange={() => {
            if (selectRef.current) {
              selectRef.current.option = options[selectRef.current.selectedIndex] || null;
              onChange(selectRef.current.value, selectRef.current.option);
            }
          }}
          ref={selectRef as LegacyRef<ExtendedHTMLSelectElement>}>
          {options.map((option, i) => {
            return (
              <option
                key={i}
                value={option.value}
                disabled={!!option.disabled}
                >
                {option.label}
              </option>
            )
          })}
        </select>
      </div>
      {error && <div className="error-text">{error}</div>}
    </div>
  );

});

export default Select;
