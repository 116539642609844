import { useState } from "react";
import { Link } from "react-router-dom"
import Button from "../button/Button";
import { DiscordLinkSchema } from "../../utils/types"
import API, { ErrorWithDetails } from "../../utils/api";
import { AlertModal } from "../modals/AlertModal";

import { X } from "react-feather";

import "./DiscordLinkCard.scss";

export default function DiscordLinkCard(
  {
    discordLink,
    onUnlink = (id: string) => {}
  } :
  {
    discordLink: DiscordLinkSchema,
    onUnlink?: (id: string) => void;
  }
) {

  const [showAlert, setShowAlert] = useState(false);

  const unlinkBot = async () => {
    const id = discordLink.unique_id;
    try {
      const unlinkResponse = await API.del('v1/discord_links', {id});
      if (unlinkResponse) {
        onUnlink(id);
      }
    } catch (e) {
      const error = e as ErrorWithDetails
      alert(error.message);
    }
  };

  return (
    <>
      {showAlert && (
        <AlertModal
          message="Unlink this bot?"
          onClose={() => setShowAlert(false)}
          onConfirm={async (complete) => {
            await unlinkBot();
            complete();
          }}
          onCancel={() => {}}
        />
      )}
      <div data-component="DiscordLinkCard">
        <div>
          <div>
            Guild ID: {discordLink.discord_guild_id}
          </div>
          <div>
            Guild Name: {discordLink.discord_guild_metadata.name}
          </div>
          {(
            discordLink.discordAgent
              ? (
                  <div>
                    Agent: {discordLink.discordAgent.agent.agentConfigs[0].name}
                  </div>
                )
              : (
                  <div>( No Agent Linked )</div>
                )
          )}
          <div>
            <Link to={`/setup?discord_link_id=${discordLink.unique_id}`}>
              Change linked agent
            </Link>
          </div>
          <div className="actions">
            <Button onClick={() => setShowAlert(true)}
              icon={X}
              label="Unlink"
              size="small"
              />
          </div>
        </div>
      </div>
    </>
  );

}