import Button from "../components/button/Button";
import { ArrowRight, ChevronRight } from "react-feather";
import "./IndexPage.scss";

export default function IndexPage() {
  return (
    <div data-component="IndexPage">
      <div className="hero">
        <div className="hero-info">
          <div className="hero-title">
            Build AI bots + functions
            <span className="tag">beta</span>
          </div>
          <div className="hero-desc">
            Create a Discord bot, shape its personality,<br />
            and extend it with custom functions.
          </div>
          <div className="hero-cta">
            <Button
              label="Try free"
              color="green"
              icon={ArrowRight}
              href="/signup"
              />
          </div>
        </div>
        <div className="hero-image">
          <img src="/funct-fox-1024.png" />
        </div>
      </div>
    </div>
  )
}